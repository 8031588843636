.projects-wrapper {
  background-color: var(--secondary-background-color);
  padding: 3% 0 1% 0;
}
body {
  color: white;
}
.project-content {
  width: 85%;
  max-width: 1300px;
  margin: 4% auto;
}
