.button {
  position: relative;
  display: inline-block;
  background: transparent;
  color: var(--text-color);
  border: 2px solid var(--border-color);
  padding: 0.3em 1.5em 0.3em 0.1em;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  margin: 0;

  border-radius: var(--border-radius);

  overflow: hidden;
  transition: all var(--animation-duration);

  z-index: 1;
}

.text {
  position: relative;
}
.button:hover
/* ,
.button:focus */ {
  color: var(--hover-text-color);
  border: 2px solid var(--hover-border-color);
  padding: 0.3em 2.5em 0.3em 0.7em;
  margin: 0;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--hover-color);
  transform: translate(-115%, 0) skew(-0deg);
  transition: all var(--animation-duration);
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 30px) 100%, 0% 100%);
}
.button:hover::before
/* ,
.button:focus::before */ {
  transform: translate(-0%, 0) skew(-0deg);
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 30px) 100%, 0% 100%);
}

.button:hover + .logo {
  opacity: 1;
}

.logo {
  opacity: 0;
  /* width: 20px; */
  height: 30px;
  filter: invert(var(--force-icon-color)) sepia(0%) saturate(0%)
    hue-rotate(317deg) brightness(200%) contrast(2200%);
  transition: all var(--animation-duration) ease-in-out;
}
