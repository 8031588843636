.awards-wrapper {
  background-color: var(--primary-background-color);
  padding: 5% 0 8% 0;
}
.awards-content {
  width: 95%;
  max-width: 1300px;
  margin: 0% auto;
}

.separator {
  width: 80%;
  border-radius: 0 12px 12px 0;
  margin: 4% 0;
  border-bottom: 3px solid var(--primary-color);
}

.header-awarditem-padding {
  height: clamp(0.5em, 2vw, 4em);
}
