.button {
  position: relative;
  display: inline-block;
  background: var(--color);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  font: inherit;
  line-height: 1;
  margin: 0;
  padding: 0;
  border-radius: 0.25em;
  box-shadow: none;
  overflow: hidden;
  transition: all var(--animation-duration);
  padding: var(--padding);
  z-index: 1;
  font-size: clamp(14px, 2vw, 18px);
}

.text {
  position: relative;
}

.button:hover
/* ,
.button:focus  */ {
  color: var(--hover-text-color);
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover-shadow-color);
  border: 2px solid var(--hover-border-color);
  transform: translateY(-0.35em);
}
