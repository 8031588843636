@import "../../../bootstrapimports.scss";
.project-title {
  font-weight: bold;
  font-size: clamp(21px, 2.5vw, 27px);
  margin: 23px 0;
  color: var(--primary-color);
}

.projectitem-wrapper {
  padding: 2em 0 5em 0;
}

.project-image {
  display: inline-block;
  width: 35%;
}

.project-resource-btn {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.project-resource-btn-top {
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
}

.project-description {
  margin-bottom: 3em;
  font-size: clamp(16px, 2vw, 18px);
}

.techstackusedtext {
  color: var(--primary-color);
  font-weight: 600;
  padding-bottom: 0.5em;
}

.techstackssectiom {
  margin-bottom: 2em;
}

.lg-stats-wrapper {
  display: none;
  width: 90%;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;
}

.stacker-image-wrapper {
  width: 80%;
}

.reacher-image-wrapper {
  width: 90%;
}

.button-spacing {
  width: clamp(12px, 2vw, 24px);
}
.hover-button-section {
  display: flex;
  flex-direction: column;
}
@include media-breakpoint-down(xl) {
  .stacker-image-wrapper {
    width: 95%;
  }
  .reacher-image-wrapper {
    width: 100%;
  }
}

@include media-breakpoint-down(lg) {
  .lg-stats-wrapper {
    display: block;
  }
  .xl-images {
    display: none !important;
  }
  .project-title {
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  .techstackusedtext {
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .project-resource-btn {
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .project-resource-btn {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 420px) {
  .project-resource-btn-top {
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .project-resource-btn {
    flex-direction: column;
    row-gap: 0.5em;
    & > * {
      width: 100%;
    }
  }

  .project-resource-btn-top {
    flex-direction: column;
    align-items: center;
  }

  .hover-button-section {
    width: 100%;
    p {
      text-align: center;
    }
  }
  .raised-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0.5em;
  }
}
